<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">货品管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-input v-model="zwm" placeholder="请输入中文名" class="iw" ></el-input>
                <el-input v-model="ywm" placeholder="请输入英文名" class="iw" ></el-input>
                <el-input v-model="dm" placeholder="请输入代码" class="iw" ></el-input>
                <el-input v-model="ghs" placeholder="请输入供货商" class="iw" ></el-input>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <el-table-column prop="Name" label="图片">
                        <template slot-scope="scope">
                            <el-image v-if="scope.row.Files.length>0"
                                style="width: 20px; height: 20px;margin-top:3px;"
                                :src="scope.row.Files[0].Path" 
                                :preview-src-list="[scope.row.Files[0].Path]">
                            </el-image>
                        </template>
                    </el-table-column>
                    <el-table-column prop="Name" label="中文名"></el-table-column>
                    <el-table-column prop="EnglishName" label="英文名"></el-table-column>
                    <el-table-column prop="Code" label="代码"></el-table-column>
                    <el-table-column prop="Standards" label="规格"></el-table-column>
                    <el-table-column prop="Unit" label="计量单位"></el-table-column>
                    <el-table-column prop="Supplier" label="供货商"></el-table-column>
                    <el-table-column prop="InWareHouseCount" label="库存数量">
                        <template slot-scope="scope">
                            <span v-if="scope.row.InWareHouseCount">{{scope.row.InWareHouseCount}}</span>
                            <span v-else>0</span>
                        </template>
                    </el-table-column>
                    <el-table-column label="操作" width="200">
                        <template slot-scope="scope">
                            <el-button @click="thresholdPost(scope.row)" type="text" size="small" style="color:#67C23A">阈值设置</el-button>
                            <el-button @click="InOutPost(scope.row)" type="text" size="small" style="color:#409EFF">库存</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="100px" class="demo-uForm">
                <el-form-item label="中文名" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="英文名" prop="englishname">
                    <el-input v-model="postInfo.englishname" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="代码" prop="code">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="规格" prop="standards">
                    <el-input v-model="postInfo.standards" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="计量单位" prop="unit">
                    <el-input v-model="postInfo.unit" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="供货商" prop="supplier">
                    <el-input v-model="postInfo.supplier" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="图片">
                    <el-upload
                            class="avatar-uploader"
                            action="https://jsonplaceholder.typicode.com/posts/"
                            :show-file-list="false"
                            :http-request="uploadFunD"
                            :before-upload="beforeAvatarUploadD">
                            <img v-if="imageUrlD" :src="imageUrlD" class="avatar">
                            <el-button v-else type="primary" size="mini">添加图标</el-button>
                        </el-upload>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="库存操作" :visible.sync="storeModal" width="1200px" :close-on-click-modal="false" top="30px" @close="storeModalClose">
                <div style="width:100%;overflow:hidden;margin-bottom:20px;">
                    <el-cascader v-model="selectColumn"  :props="storepropsA" placeholder="选择存放位置"  filterable clearable style="width:60%;margin-right:20px" ></el-cascader>
                    <el-button type="primary" @click="searchPostS">查询</el-button>
                    <el-button type="success" @click="InPost" class="fr" v-if="sysType=='2'">新增入库</el-button>
                </div> 
                <el-table :data="storeList" stripe style="width: 100%" :height="dialogMaxHeigh"> 
                    <el-table-column prop="WareHouseName" label="仓库名称"></el-table-column>
                    <el-table-column prop="WareHouseCode" label="仓库代码"></el-table-column>
                    <el-table-column prop="AreaName" label="区域名称"></el-table-column>
                    <el-table-column prop="AreaCode" label="区域代码"></el-table-column>
                    <el-table-column prop="ShelvingCode" label="货架代码"></el-table-column>
                    <el-table-column prop="ShelvingRowIndex" label="行号"></el-table-column>
                    <el-table-column prop="Index" label="货口号"></el-table-column>
                    <el-table-column prop="AllCode" label="货口代码"></el-table-column>
                    <el-table-column prop="GoodCount" label="存放数量"></el-table-column>
                    <el-table-column label="操作" width="100">
                        <template slot-scope="scope">
                            <el-button @click="movePost(scope.row)" type="text" size="small" style="color:#E6A23C" v-if="sysType=='2'">调拨</el-button>
                            <el-button @click="outPost(scope.row)"  type="text" size="small" style="color:#F56C6C" v-if="sysType=='2'">出库</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndexS" :page-size="pageSizeS" :total="TotalS" class="pg" @current-change="PGChangeS"></el-pagination>
                <div style="height:20px;width:100%;float:left;"></div>
        </el-dialog>
        <el-dialog title="货品入库" :visible.sync="goodsInModal" width="1000px" :close-on-click-modal="false" top="100px">
            <el-form :model="goodsInInfo" status-icon :rules="goodsInrules"  ref="inForm" label-width="100px" class="demo-uForm">
                <el-form-item label="存放位置" prop="position" v-if="goodsInModal">
                    <el-cascader v-model="goodsInInfo.position"  :props="storepropsB" placeholder="选择存放位置"  filterable style="width:100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="入库数量" prop="goodCount">
                    <el-input v-model="goodsInInfo.goodCount" autocomplete="off"  min="1"></el-input>
                </el-form-item>
                <el-form-item label="入库单价" prop="oneMoney">
                    <el-input v-model="goodsInInfo.oneMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="总额" prop="money">
                    <el-input v-model="goodsInInfo.money" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货物来源" prop="source">
                    <el-input v-model="goodsInInfo.source" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="goodsInInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitInPostForm()">提交</el-button>
                    <el-button @click="goodsInModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="货品调拨" :visible.sync="goodsMoveModal" width="1000px" :close-on-click-modal="false" top="100px">
            <el-form :model="goodsMoveInfo" status-icon :rules="goodsMoverules"  ref="moveForm" label-width="120px" class="demo-uForm">
                <el-form-item label="调拨前存放位置" v-if="goodsMoveModal">
                    <p>{{saveItem.WareHouseName}}/{{saveItem.AreaName}}/{{saveItem.ShelvingCode}}号货架/第{{saveItem.ShelvingRowIndex}}排/{{saveItem.Index}}号口</p>
                </el-form-item>
                <el-form-item label="调拨后存放位置" prop="mposition" v-if="goodsMoveModal">
                    <el-cascader v-model="goodsMoveInfo.mposition"  :props="storeprops" placeholder="选择存放位置"  filterable style="width:100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="调入数量" prop="mgoodCount" v-if="goodsMoveModal">
                    <el-input v-model="goodsMoveInfo.mgoodCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="调拨原因" prop="dbyy">
                    <el-input v-model="goodsMoveInfo.dbyy" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitMovePostForm()">提交</el-button>
                    <el-button @click="goodsMoveModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="货品出库" :visible.sync="goodsOutModal" width="1000px" :close-on-click-modal="false" top="100px">
            <el-form :model="goodsOutInfo" status-icon :rules="goodsOutrules"  ref="outForm" label-width="120px" class="demo-uForm">
                <el-form-item label="当前存放位置" v-if="goodsOutModal">
                    <p>{{saveItem.WareHouseName}}/{{saveItem.AreaName}}/{{saveItem.ShelvingCode}}号货架/第{{saveItem.ShelvingRowIndex}}排/{{saveItem.Index}}号口</p>
                </el-form-item>
                <el-form-item label="当前库存数量" v-if="goodsOutModal">
                    <p>{{saveItem.GoodCount}}</p>
                </el-form-item>
                <el-form-item label="出库数量" prop="ogoodCount" v-if="goodsOutModal">
                    <el-input v-model="goodsOutInfo.ogoodCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库金额" prop="OutMoney">
                    <el-input v-model="goodsOutInfo.OutMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库用途" prop="target">
                    <el-input v-model="goodsOutInfo.target" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="bz">
                    <el-input v-model="goodsOutInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitOutPostForm()">提交</el-button>
                    <el-button @click="goodsOutModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog :title="doTypeStr" :visible.sync="goodsThresholdModal" width="800px" :close-on-click-modal="false">
            <p style="font-size:14px;color:#666666;margin-bottom:20px;" v-if="saveItem">请设置<span style="color:#E6A23C;font-weight: bold;"> {{saveItem.Name}} </span>在各仓库的库存阈值，当货品出库后低于此阈值时会提醒库管及时补货(0视为不提醒)</p>
            <div style="overflow:hidden;margin-bottom:20px;" v-for="(item,index) in storeThresholdList" :key="item.SnowID">
                <p style="font-size:14px;font-weight: bold;color:#F56C6C;margin-bottom:5px;">{{item.Name}}（{{item.Type}}）</p>
                <el-input v-model="item.threshold" autocomplete="off" placeholder="请输入阈值" style="width:80%;margin-right:20px;"></el-input>
                <el-button type="primary" @click="submitThresholdForm(index)">保存</el-button>
            </div>
        </el-dialog>
    </div>
</template>
<script>
var h=document.body.clientHeight
export default {
  name: "UnitManage",

  data() {
    var name = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入中文名"));
      } else {
        callback();
      }
    };
    var englishname = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入英文名"));
      } else {
        callback();
      }
    };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入代码"));
      } else {
        callback();
      }
    };
    var standards = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入规格"));
      } else {
        callback();
      }
    };
    var unit = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入计量单位"));
      } else {
        callback();
      }
    };
    var supplier = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入供货商"));
      } else {
        callback();
      }
    };  
    
    var position=(rule, value, callback) => {
      if (value.length==0) {
        callback(new Error("请选择存放位置"));
      } else {
        callback();
      }
    };  

    var mposition=(rule, value, callback) => {
      if (value.length==0) {
        callback(new Error("请选择调拨后存放位置"));
      }else if(value[4] === this.saveItem.SnowID){
        callback(new Error("请选择调拨前后位置不能一样"));
      } else {
        callback();
      }
    };  

    var count= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };

    var mgoodCount= (rule, value, callback) => {
      if (value === '') {
            callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else if(value > this.saveItem.GoodCount){
            callback(new Error('调拨数量不能大于库存数量'));
        }else {
          callback();
        }
    };

    var ogoodCount= (rule, value, callback) => {
      if (value === '') {
            callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else if(value > this.saveItem.GoodCount){
            callback(new Error('出库数量不能大于库存数量'));
        }else {
          callback();
        }
    };

    var money=(rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('金额只能是数字'));
        }else if(value<0){
            callback(new Error('金额必须大于0'));
        }else {
          callback();
        }
    };

    var source = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入货物来源"));
      } else {
        callback();
      }
    };  

    var dbyy = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入调拨原因"));
      } else {
        callback();
      }
    };  

    var target = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入出库用途"));
      } else {
        callback();
      }
    }; 
    return {
        sysType:"",

        dialogMaxHeigh:0,
        storepropsA: {
            lazy: true,
            lazyLoad :(node, resolve)=> {
                var src="WareHouse/GetDataList"
                var type="1"
                var params={}
                var leaf=false
                if(node && node.data!=undefined){
                    if(node.data.type=="1"){
                        src="Area/GetDataList"
                        type="2"
                        params={
                            wareHouseSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="2"){
                        src="Shelving/GetDataList"
                        type="3"
                        params={
                            areaSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="3"){
                        src="ShelvingRow/GetDataList"
                        type="4"
                        params={
                            shelvingSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="4"){
                        src="ShelvingColumn/GetDataList"
                        type="5"
                        leaf=true
                        params={
                            shelvingRowSnowID:node.data.snowid
                        }
                    }
                }
                var nodes=[]
                
                this.$http.post(src,params)
                .then((response) => {
                    if(response.data.SUCCESS){   
                        var l=response.data.DATA
                        l.sort(function (n, m) {
                            return n.Index - m.Index;
                        });
                        for(var i=0;i<l.length;i++){
                            var disabled=false
                            if(type=="1"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name+"("+l[i].Type+")",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="2"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="3"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Code+"号货架",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="4"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:"第"+l[i].Index+"行",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="5"){
                                let name=l[i].Index+"号口"
                                if(l[i].GoodSnowID &&l[i].GoodName){
                                    name+="(现存："+l[i].GoodName+"×"+l[i].GoodCount+")"
                                    // if(this.nowGoods && l[i].GoodSnowID && l[i].GoodSnowID != this.nowGoods.snowID){
                                    //     disabled=true
                                    // }
                                }    
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }  
                        }
                        resolve(nodes);
                    }else{
                        resolve(nodes);
                    }
                })
            }
        },
        storepropsB: {
            lazy: true,
            //checkStrictly:true,
            lazyLoad :(node, resolve)=> {
                var src="WareHouse/GetDataList"
                var type="1"
                var params={}
                var leaf=false
                
                if(node && node.data!=undefined){
                    if(node.data.type=="1"){
                        src="Area/GetDataList"
                        type="2"
                        params={
                            wareHouseSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="2"){
                        src="Shelving/GetDataList"
                        type="3"
                        params={
                            areaSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="3"){
                        src="ShelvingRow/GetDataList"
                        type="4"
                        params={
                            shelvingSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="4"){
                        src="ShelvingColumn/GetDataList"
                        type="5"
                        leaf=true
                        params={
                            shelvingRowSnowID:node.data.snowid
                        }
                    }
                }
                var nodes=[]
                
                this.$http.post(src,params)
                .then((response) => {
                    if(response.data.SUCCESS){   
                        var l=response.data.DATA
                        l.sort(function (n, m) {
                            return n.Index - m.Index;
                        });
                        for(var i=0;i<l.length;i++){
                            let disabled=false
                            if(type=="1"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name+"("+l[i].Type+")",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="2"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="3"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Code+"号货架",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="4"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:"第"+l[i].Index+"行",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="5"){
                                let name=l[i].Index+"号口"
                                if(l[i].GoodSnowID  && this.nowGoods){
                                    name+="(现存："+l[i].GoodName+"×"+l[i].GoodCount+")"
                                    if(l[i].GoodSnowID != this.nowGoods.SnowID){
                                        disabled=true
                                    }
                                }    
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }  
                        }
                        resolve(nodes);
                    }else{
                        resolve(nodes);
                    }
                })
            }
        },
        storeprops: {
            lazy: true,
            //checkStrictly:true,
            lazyLoad :(node, resolve)=> {
                var src="WareHouse/GetDataList"
                var type="1"
                var params={}
                var leaf=false
                
                if(node && node.data!=undefined){
                    if(node.data.type=="1"){
                        src="Area/GetDataList"
                        type="2"
                        params={
                            wareHouseSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="2"){
                        src="Shelving/GetDataList"
                        type="3"
                        params={
                            areaSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="3"){
                        src="ShelvingRow/GetDataList"
                        type="4"
                        params={
                            shelvingSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="4"){
                        src="ShelvingColumn/GetDataList"
                        type="5"
                        leaf=true
                        params={
                            shelvingRowSnowID:node.data.snowid
                        }
                    }
                }
                var nodes=[]
                
                this.$http.post(src,params)
                .then((response) => {
                    if(response.data.SUCCESS){   
                        var l=response.data.DATA
                        l.sort(function (n, m) {
                            return n.Index - m.Index;
                        });
                        for(var i=0;i<l.length;i++){
                            let disabled=false
                            if(type=="1"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name+"("+l[i].Type+")",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="2"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="3"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Code+"号货架",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="4"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:"第"+l[i].Index+"行",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="5"){
                                let name=l[i].Index+"号口"
                                if(l[i].GoodSnowID  && this.nowGoods){
                                    name+="(现存："+l[i].GoodName+"×"+l[i].GoodCount+")"
                                    if(l[i].GoodSnowID != this.nowGoods.SnowID){
                                        disabled=true
                                    }else if(this.saveItem.SnowID == l[i].SnowID){
                                        disabled=true
                                    }
                                }
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }  
                        }
                        resolve(nodes);
                    }else{
                        resolve(nodes);
                    }
                })
            }
        },
        canDo:true,
        showPagination:false,
        zwm:"",
        ywm:"",
        dm:"",
        ghs:"",

        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            name: [{ validator: name, trigger: "blur" }],
            englishname: [{ validator: englishname, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
            standards: [{ validator: standards, trigger: "blur" }],
            unit: [{ validator: unit, trigger: "blur" }],
            supplier: [{ validator: supplier, trigger: "blur" }],
        },

        imageFile:null,
        imageUrlD:"",

        storeTreeList:[],

        storeModal:false,
        nowGoods:null,
        storeList:[],
        pageIndexS:1,
        pageSizeS:10,
        TotalS:0,
        selectColumn:[],

        goodsInModal:false,
        goodsInInfo:{},
        goodsInrules:{
            position: [{ validator: position, trigger: "blur" }],
            goodCount: [{ validator: count, trigger: "blur" }],
            oneMoney: [{ validator: money, trigger: "blur" }],
            money: [{ validator: money, trigger: "blur" }],
            source: [{ validator: source, trigger: "blur" }],
        },

        goodsMoveModal:false,
        goodsMoveInfo:{},
        goodsMoverules:{
            mposition: [{ validator: mposition, trigger: "blur" }],
            mgoodCount: [{ validator: mgoodCount, trigger: "blur" }],
            dbyy: [{ validator: dbyy, trigger: "blur" }],
        },

        goodsOutModal:false,
        goodsOutInfo:{},
        goodsOutrules:{
            ogoodCount: [{ validator: ogoodCount, trigger: "blur" }],
            OutMoney: [{ validator: money, trigger: "blur" }],
            target: [{ validator: target, trigger: "blur" }],
        },

        saveItem:null,

        storeThresholdList:[],
        goodsThresholdModal:false,

    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType
    this.dialogMaxHeigh = h -300
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.zwm=getQueryData.name
        this.ywm=getQueryData.englishName
        this.dm=getQueryData.code
        this.ghs=getQueryData.supplier
    }
    this.loadItem=null
    this.getPostList()
    this.getAllStoreList()
  },
  methods: {
    storeModalClose(){
        this.nowGoods=null  
    },
    getAllStoreList(){
        var params={
           
        }
        this.$http.post("WareHouse/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                   var list =response.data.DATA
                   for(var i=0;i<list.length;i++){
                        var d=list[i]
                        d.threshold=0
                        d.InCount=0
                        this.storeThresholdList.push(d)
                   }
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            name:this.zwm,
            englishName:this.ywm,
            code:this.dm,
            supplier:this.ghs,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Good/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },
    addPost(){
        this.postInfo={
            name:"",
            englishname:"",
            code:"",
            standards:"",
            unit:"",
            supplier:"",
        }
        this.imageFile=null
        this.imageUrlD=""
        this.doTypeStr="添加货品"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Good/InsertData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    if(this.imageFile){
                        this.addImg(response.data.DATA)
                    }
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.imageFile=null
        this.imageUrlD=""
        this.postInfo={
            snowID:item.SnowID,
            name:item.Name,
            englishname:item.EnglishName,
            code:item.Code,
            standards:item.Standards,
            unit:item.Unit,
            supplier:item.Supplier,
        }
        this.doTypeStr="修改货品"
        this.doType="2"
        this.postModal=true
        if(item.Files.length>0){
            this.imageUrlD=item.Files[0].Path
        }
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Good/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    if(this.imageFile){
                        this.addImg(this.postInfo.snowID)
                    }
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },


    beforeAvatarUploadD(file) {
        const isJPG = file.type === 'image/jpeg' || file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 2;

        if (!isJPG) {
        this.$message.error('图片只能是 JPG/PNG  格式!');
        }else if (!isLt2M) {
        this.$message.error('图片大小不能超过 2MB!');
        }else{
            var _URL = window.URL || window.webkitURL;
            this.imageUrlD = _URL.createObjectURL(file);
            this.imageFile=file
        }
        return isJPG && isLt2M;
    },
    uploadFunD(file){
        //console.log(file)
    },

    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除货品：'+item.Name+'？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    addImg(id){
        var fd = new FormData();
        fd.append("file", this.imageFile);
        this.$upload.post("File/UpLoadFile?SourceSnowID="+id+"&FileType=1&DeleteOld=1", fd)
        .then((response) => {
                if (response.data.SUCCESS) {
                    this.getPostList()
                }
            })
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Good/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                } 
            });
    },
    InOutPost(item){
        this.nowGoods=item
        this.selectColumn=[]
        this.pageIndexS=1
        this.getGoodsStores()
        this.storeModal=true
    },
    getGoodsStores(){
        this.showLoading("请稍后")
        var hk=""
        if(this.selectColumn.length==5){
            hk=this.selectColumn[4]
        }
        var params={
            pageIndex:this.pageIndexS,
            pageSize:this.pageSizeS,
            goodSnowID:this.nowGoods.SnowID,
            snowID:hk,
        }
        this.$http.post("ShelvingColumn/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.storeList=response.data.DATA
                this.TotalS=response.data.TOTALCOUNT
            } else {
                this.storeList=[]
                this.TotalS=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.storeList=[]
                this.TotalS=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPostS(){
        this.pageIndexS=1
        this.getGoodsStores()
    },
    PGChangeS(e){
        this.pageIndexS=e
        this.getGoodsStores()
    },

    InPost(){
        this.goodsInInfo={
            position:[],
            goodCount:"",
            oneMoney:"",
            money:"",
            source:"",
            bz:"",
        }
        this.goodsInModal=true
    },
    submitInPostForm(){
        if (this.canDo) {
            this.$refs.inForm.validate((valid) => {
                if (valid) {
                    this.sendGoodsIn()
                } else {
                    return false;
                }
            });
        }
    },
    sendGoodsIn(){
        var params={
            goodSnowID:this.nowGoods.SnowID,
            shelvingColumnSnowID:this.goodsInInfo.position[4],
            goodCount:parseInt(this.goodsInInfo.goodCount),
            oneMoney:parseFloat(this.goodsInInfo.oneMoney),
            money:parseFloat(this.goodsInInfo.money),
            source:this.goodsInInfo.source,
            bz:this.goodsInInfo.bz,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/InWareHouse", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("入库完成")
                    this.goodsInModal=false
                    this.getGoodsStores()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    movePost(item){
        this.saveItem=item
        this.goodsMoveInfo={
            mposition:[],
            mgoodCount:"",
            dbyy:"",
        }
        this.goodsMoveModal=true
    },
    submitMovePostForm(){
        if (this.canDo) {
            this.$refs.moveForm.validate((valid) => {
                if (valid) {
                    this.sendGoodsMove()
                } else {
                    return false;
                }
            });
        }
    },
    sendGoodsMove(){
        var params={
            oldShelvingColumnSnowID:this.saveItem.SnowID,
            shelvingColumnSnowID:this.goodsMoveInfo.mposition[4],
            goodCount:parseInt(this.goodsMoveInfo.mgoodCount),
            bz:this.goodsMoveInfo.dbyy,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/WareHouseDispatch", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    //this.$message.success("调拨完成")
                    this.afterOutFlagCount("调拨完成")
                    this.goodsMoveModal=false
                    this.getGoodsStores()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    outPost(item){
        this.saveItem=item
        this.goodsOutInfo={
            ogoodCount:"",
            OutMoney:"",
            target:"",
            bz:"",
        }
        this.goodsOutModal=true
    },
    submitOutPostForm(){
        if (this.canDo) {
            this.$refs.outForm.validate((valid) => {
                if (valid) {
                    this.sendOutGoods()
                } else {
                    return false;
                }
            });
        }
    },
    sendOutGoods(){
        var params={
            shelvingColumnSnowID:this.saveItem.SnowID,
            goodCount:parseInt(this.goodsOutInfo.ogoodCount),
            OutMoney:parseFloat(this.goodsOutInfo.OutMoney),
            bz:this.goodsOutInfo.bz,
            target:this.goodsOutInfo.target,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/OutWareHouse", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    //this.$message.success("出库完成")
                    this.afterOutFlagCount("出库完成")
                    this.goodsOutModal=false
                    this.getGoodsStores()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },

    afterOutFlagCount(str){
        var params={
            wareHouseSnowID:this.saveItem.WareHouseSnowID,
            goodSnowID:this.nowGoods.SnowID,
        }
        this.$http.post("WarningSetting/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    if(d.GoodCount >0 && d.WareHouseCount<d.GoodCount){
                        var tag='商品 <span style="color:#409EFF;">'+this.nowGoods.Name+'</span> 在 <span style="color:#E6A23C;">'+this.saveItem.WareHouseName+'</span> 中的库存还剩余 <span style="color:#F56C6C;">'+d.WareHouseCount+'</span> 件,低于阈值所设置的  <span style="color:#F56C6C;">'+d.GoodCount+'</span> 件，请及时补充货品！'
                        this.$alert(tag, str,{
                            dangerouslyUseHTMLString: true 
                        });
                       
                    }else{
                        this.$message.success(str)
                    }
                }else{
                    this.$message.success(str)
                }
            })
    },


    thresholdPost(item){
        if(this.canDo){
            this.saveItem=item
            this.doTypeStr=item.Name+"-阈值设置"
            this.getGoodsThreshold()
        }
    },
    getGoodsThreshold(){
        var params={
            goodSnowID:this.saveItem.SnowID,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WarningSetting/GetDataList", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.setGoodsThreshold(response.data.DATA)
                }else{
                    this.setGoodsThreshold([])
                }
            })
            .catch((error) => {
                this.setGoodsThreshold([])
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    setGoodsThreshold(list){
        var obj={}
        for(var i=0;i<this.storeThresholdList.length;i++){
            var d=this.storeThresholdList[i]
            d.threshold=0
            obj[d.SnowID]=d
        }

        for(var j=0;j<list.length;j++){
            var t=list[j]
            obj[t.WareHouseSnowID].threshold=t.GoodCount
            obj[t.WareHouseSnowID].InCount=t.WareHouseCount
        }

        var nl=[]
        for(var item in obj){
            nl.push(obj[item])
        }
        this.storeThresholdList=nl
        this.goodsThresholdModal=true
    },
    submitThresholdForm(index){
        var num=this.storeThresholdList[index].threshold
        if(typeof num != 'number' && isNaN(num)){
            this.$message.error("阈值只能是数字")
        }else if(!/^\+?(0|[1-9][0-9]*)$/.test(num)){
            this.$message.error("只能输入大于等于0的整数")
        }else{
            var params={
                wareHouseSnowID:this.storeThresholdList[index].SnowID,
                goodSnowID:this.saveItem.SnowID,
                goodCount:num,
            }
            this.sendThresholdSave(params)
        }
    },
    sendThresholdSave(params){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("WarningSetting/InsertOrUpdateData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("设置成功！")
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>