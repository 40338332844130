<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">出入库查询</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <el-select v-model="status" placeholder="选择类型"  filterable  class="iwu" @change="searchPost">
                        <el-option label="入库" value="1" ></el-option>
                        <el-option label="出库" value="4" ></el-option>
                        <el-option label="调拨" value="3" ></el-option>
                </el-select>
                <el-select v-model="goodsid" placeholder="选择货品"  filterable  clearable class="iwu">
                        <el-option v-for="item in goodsList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="userid" placeholder="选择经办人"  filterable  clearable class="iwu" v-if="sysType=='1'">
                        <el-option v-for="item in userList" :key="item.SNOWID" :label="item.DISPLAYNAME" :value="item.SNOWID" ></el-option>
                </el-select>
                
                <el-date-picker v-model="times" type="datetimerange" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" class="iwss"></el-date-picker>
                <el-cascader class="iwsss" v-model="positions"  :props="storepropsA" placeholder="选择位置"  filterable clearable ></el-cascader>
                <el-button type="primary" @click="searchPost">查询</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%" v-show="status=='1'"> 
                    <el-table-column prop="GoodName" label="入库货品"></el-table-column>
                    <el-table-column prop="SAVECODE" label="存放格口"></el-table-column>
                    <el-table-column prop="SAVECODE" label="格口位置">
                        <template slot-scope="scope">
                            <span>{{scope.row.WAREHOUSENAME}} / {{scope.row.AREANAME}} / {{scope.row.SHELVINGCODE}}号货架 / 第{{scope.row.SHELVINGROWINDEX}}行 / 第{{scope.row.SHELVINGCOLUMNINDEX}}格</span> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="GoodCount" label="入库数量" width="100"></el-table-column>
                    <el-table-column prop="BeforeCount" label="入库前数量" width="100"></el-table-column>
                    <el-table-column prop="AfterCount" label="入库后数量" width="100"></el-table-column>
                    <el-table-column prop="SOURCE" label="货品来源"></el-table-column>
                    <el-table-column prop="ONEMONEY" label="单价"></el-table-column>
                    <el-table-column prop="MONEY" label="总价"></el-table-column>
                    <el-table-column prop="UserName" label="经办人" width="70"></el-table-column>
                    <el-table-column prop="CreateTime" label="时间"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                </el-table>
                <el-table :data="postList" stripe style="width: 100%" v-show="status=='3'"> 
                    <el-table-column prop="GoodName" label="调拨货品"></el-table-column>
                    <el-table-column prop="GoodCount" label="调拨数量" width="100"></el-table-column>
                    <el-table-column prop="OLDSAVECODE" label="调出格口"></el-table-column>
                    <el-table-column prop="OLDSAVECODE" label="格口位置">
                        <template slot-scope="scope">
                            <span>{{scope.row.OLDWAREHOUSENAME}} / {{scope.row.OLDAREANAME}} / {{scope.row.OLDSHELVINGCODE}}号货架 / 第{{scope.row.OLDSHELVINGROWINDEX}}行 / 第{{scope.row.OLDSHELVINGCOLUMNINDEX}}格</span> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="OldBeforeCount" label="调出前后库存数量">
                        <template slot-scope="scope">
                            调出前：{{scope.row.OldBeforeCount}}　调出后：{{scope.row.OldAfterCount}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="SAVECODE" label="调入格口"></el-table-column>
                    <el-table-column prop="SAVECODE" label="格口位置">
                        <template slot-scope="scope">
                            <span>{{scope.row.WAREHOUSENAME}} / {{scope.row.AREANAME}} / {{scope.row.SHELVINGCODE}}号货架 / 第{{scope.row.SHELVINGROWINDEX}}行 / 第{{scope.row.SHELVINGCOLUMNINDEX}}格</span> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="AfterCount" label="调入前后库存数量">
                        <template slot-scope="scope">
                            调入前：{{scope.row.BeforeCount}}　调入后：{{scope.row.AfterCount}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="UserName" label="经办人" width="70"></el-table-column>
                    <el-table-column prop="CreateTime" label="时间"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                </el-table>
                <el-table :data="postList" stripe style="width: 100%" v-show="status=='4'"> 
                    <el-table-column prop="GoodName" label="出库货品"></el-table-column>
                    <el-table-column prop="GoodCount" label="出库数量" width="100"></el-table-column>
                    <el-table-column prop="SAVECODE" label="出库前格口"></el-table-column>
                    <el-table-column prop="SAVECODE" label="格口位置">
                        <template slot-scope="scope">
                            <span>{{scope.row.WAREHOUSENAME}} / {{scope.row.AREANAME}} / {{scope.row.SHELVINGCODE}}号货架 / 第{{scope.row.SHELVINGROWINDEX}}行 / 第{{scope.row.SHELVINGCOLUMNINDEX}}格</span> 
                        </template>
                    </el-table-column>
                    <el-table-column prop="BeforeCount" label="出库前数量" width="100"></el-table-column>
                    <el-table-column prop="AfterCount" label="出库后数量" width="100"></el-table-column>
                    <el-table-column prop="MONEY" label="出库金额"></el-table-column>
                    <el-table-column prop="Target" label="出库去向"></el-table-column>
                    
                    <el-table-column prop="UserName" label="经办人" width="70"></el-table-column>
                    <el-table-column prop="CreateTime" label="时间"></el-table-column>
                    <el-table-column prop="BZ" label="备注"></el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
    </div>
</template>
<script>

export default {
  name: "UnitManage",

  data() {

    
    return {
        sysType:"",
        canDo:true,
        showPagination:false,
        
        status:"1",
        positions:[],
        goodsid:"",
        goodsList:[],
        userid:"",
        userList:[],
        times:[],


        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,
        storepropsA: {
            lazy: true,
            checkStrictly:true,
            lazyLoad :(node, resolve)=> {
                var src="WareHouse/GetDataList"
                var type="1"
                var params={}
                var leaf=false
                if(node && node.data!=undefined){
                    if(node.data.type=="1"){
                        src="Area/GetDataList"
                        type="2"
                        params={
                            wareHouseSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="2"){
                        src="Shelving/GetDataList"
                        type="3"
                        params={
                            areaSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="3"){
                        src="ShelvingRow/GetDataList"
                        type="4"
                        params={
                            shelvingSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="4"){
                        src="ShelvingColumn/GetDataList"
                        type="5"
                        leaf=true
                        params={
                            shelvingRowSnowID:node.data.snowid
                        }
                    }
                }
                var nodes=[]
                
                this.$http.post(src,params)
                .then((response) => {
                    if(response.data.SUCCESS){   
                        var l=response.data.DATA
                        l.sort(function (n, m) {
                            return n.Index - m.Index;
                        });
                        for(var i=0;i<l.length;i++){
                            var disabled=false
                            if(type=="1"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name+"("+l[i].Type+")",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="2"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="3"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Code+"号货架",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="4"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:"第"+l[i].Index+"行",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="5"){
                                let name=l[i].Index+"号口"
                                if(l[i].GoodSnowID &&l[i].GoodName){
                                    name+="(现存："+l[i].GoodName+"×"+l[i].GoodCount+")"
                                }    
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }  
                        }
                        resolve(nodes);
                    }else{
                        resolve(nodes);
                    }
                })
            }
        },
    };
  },
  mounted() {
    this.sysType=this.$store.state.sysType
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
    }
    this.loadItem=null
    this.getPostList()
    this.getGoodsList()
    if(this.sysType=="1"){
        this.getUserList()
    }
  },
  methods: {
    getGoodsList(){
        this.$http.post("Good/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.goodsList=response.data.DATA
                } 
            })
    },
    getUserList(){
        var params={
            PageIndex:1,
            PageSize:200,
            UserType:"2",
            LoginName:"",
            disPlayName:"",
        }
        this.$http.post("SysUser/GetSysUserList", params)
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.userList=response.data.DATA
                } 
            })
    },
    getPostList(){
        this.showLoading("请稍后")
        var ck=""
        var qy=""
        var hj=""
        var hp=""
        var hk=""
        if(this.positions.length==1){
            ck=this.positions[0]
        }else if(this.positions.length==2){
            qy=this.positions[1]
        }else if(this.positions.length==3){
            hj=this.positions[2]
        }else if(this.positions.length==4){
            hp=this.positions[3]
        }else if(this.positions.length==5){
            hk=this.positions[4]
        }
        var uid=this.userid
        if(this.sysType=="2"){
            uid=this.$store.state.snowid
        }
        var st=""
        var et=""
        if(this.times && this.times.length==2){
            st=this.comjs.getDateStrByDate(this.times[0])
            et=this.comjs.getDateStrByDate(this.times[1])
        }
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            wareHouseSnowID:ck,
            areaSnowID:qy,
            shelvingSnowID:hj,
            shelvingRowSnowID:hp,
            shelvingColumnSnowID:hk,
            goodSnowID:this.goodsid,
            status:this.status,
            userSnowID:uid,
            startTime:st,
            endTime:et,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("GoodIn/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

  },
  computed: {
  },
  watch: {
  },
};
</script>
<style scoped>


</style>