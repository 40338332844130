<template>
    <div class="views-main">
        <div class="views-main-title">
            <p class="txt">货架管理</p>
        </div>
        <div class="tableBox" style="top:50px;">
            <div class="views-main-search">
                <!-- <el-input v-model="name" placeholder="请输入货架名称" class="iw" ></el-input> -->
                <el-input v-model="code" placeholder="请输入货架代码" class="iw" ></el-input>
                <el-select v-model="ck" placeholder="选择仓库"  filterable  clearable class="iw" @change="searchCK">
                        <el-option v-for="item in ckList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-select v-model="area" placeholder="选择区域"  filterable  clearable class="iw">
                        <el-option v-for="item in areaList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                </el-select>
                <el-button type="primary" @click="searchPost">查询</el-button>
                <el-button type="success" @click="addPost" class="fr">添加</el-button>
            </div>
            <div class="views-main-body">
                <el-table :data="postList" stripe style="width: 100%"> 
                    <!-- <el-table-column prop="Name" label="货架名称"></el-table-column> -->
                    <el-table-column prop="Code" label="区域代码"></el-table-column>
                    <el-table-column prop="WareHouseName" label="所在仓库"></el-table-column>
                    <el-table-column prop="AreaName" label="所在区域"></el-table-column>
                    <el-table-column prop="Index" label="排序"></el-table-column>
                    <el-table-column label="操作" width="160">
                        <template slot-scope="scope">
                            <el-button @click="itemPost(scope.row)" type="text" size="small" style="color:#409EFF">货口管理</el-button>
                            <el-button @click="editPost(scope.row)" type="text" size="small" style="color:#E6A23C">编辑</el-button>
                            <el-button @click="delPost(scope.row)"  type="text" size="small" style="color:#F56C6C">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <el-pagination background layout="total, prev, pager, next"  :current-page="pageIndex" :page-size="pageSize" :total="Total" class="pg" @current-change="PGChange"></el-pagination>
            </div>
        </div>
        <el-dialog :title="doTypeStr" :visible.sync="postModal" width="1200px" :close-on-click-modal="false">
            <el-form :model="postInfo" status-icon :rules="postrules"  ref="uForm" label-width="150px" class="demo-uForm">
                <!-- <el-form-item label="货架名称" prop="name">
                    <el-input v-model="postInfo.name" autocomplete="off"></el-input>
                </el-form-item> -->
                <el-form-item label="货架代码" prop="code">
                    <el-input v-model="postInfo.code" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="仓库区域" prop="areaSnowID">
                    <el-select v-model="fck" placeholder="请选择仓库"  filterable clearable style="width:49%;" @change="fromCK">
                        <el-option v-for="item in ckList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                    <el-select v-model="postInfo.areaSnowID" placeholder="请选择区域"  filterable clearable style="width:49%;margin-left:2%">
                        <el-option v-for="item in fareaList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="排序" prop="index">
                    <el-input v-model="postInfo.index" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="是否自动生成货口">
                    <el-radio v-model="autoAdd" label="1">是</el-radio>
                    <el-radio v-model="autoAdd" label="0">否</el-radio>
                </el-form-item>
                <el-form-item label="货架总行数" prop="rowCount" v-if="autoAdd=='1'">
                    <el-input v-model="postInfo.rowCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="每行货口数量" prop="columnCount" v-if="autoAdd=='1'">
                    <el-input v-model="postInfo.columnCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitPostForm()">提交</el-button>
                    <el-button @click="postModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="货架货口管理" :visible.sync="rackModal" width="1200px" :close-on-click-modal="false">
            <div v-if="nowShelving" style="overflow:hidden;border-bottom:1px solid #ADADAD;padding-bottom:12px;">
                <p style="float:left;margin-top:6px;">货架代码：{{nowShelving.Code}}</p>
                <p style="float:left;margin-left:20px;margin-top:6px;">货架位置：{{nowShelving.WareHouseName}}-{{nowShelving.AreaName}}</p>
                <div style="float:right;overflow:hidden;width:309px;">
                    <el-input v-model="addColumnCount" autocomplete="off" placeholder="输入货口数量添加新的一行" size="small" style="display:inline-bolck;width:195px;float:left;"></el-input>
                    <el-button type="success" @click="addColumnByRow()" size="small" style="float:left;margin-left:20px;">添加一行</el-button>
                </div> 
            </div>
            <div  v-if="rowColumnList.length>0" class="rowCollapse">
                <div v-for="item in rowColumnList" :key="item.ShelvingRowSnowID" class="rowCollapseRow">
                    <div class="rowCollapseTop">
                        <div  style="margin-left:12px;float:left;">
                            行号：
                            <el-input v-model="item.Index" autocomplete="off" placeholder="可在此处修改行号" size="mini" style="display:inline-bolck;width:100px;" @change="changeRowIndex(item.ShelvingRowSnowID)"></el-input>
                            <el-button size="mini" type="success" style="margin-left:12px;" @click="addOneColunm(item.ShelvingRowSnowID)">添加一个货口</el-button>
                        </div>
                        <el-button size="mini" type="danger" style="float:right;margin-right:12px;">删除这行</el-button>
                       
                    </div>                    
                    <div style="width:100%;overflow:hidden;">
                        <div  class="rowColumnItem"  v-for="column in item.ShelvingColumnList" :key="column.ShelvingColumnSnowID" :class="column.GoodSnowID?'hasGoods':'noGoods'" >
                            <div class="rowColumnItemBox">
                                <div v-if="column.GoodSnowID" class="goodsItemBox">
                                    <div class="left">
                                        <img v-if="column.Files" :src="column.Files[0].Path"/>
                                    </div>
                                    <div class="right">
                                        <p>{{column.GoodName}}</p>
                                        <p>编号：{{column.GoodCode}}</p>
                                        <p>数量：{{column.GoodCount}}</p>
                                    </div>
                                </div>
                                <span v-else>空</span>

                                <div class="hoverBox">
                                    <p class="hoverBtn"  style="color:#67C23A" :class="!column.GoodSnowID?'nogtop':''" @click="goodsIn(item,column)">货品入库</p>
                                    <p class="hoverBtn" v-if="column.GoodSnowID" style="color:#F56C6C;" @click="goodsOut(item,column)">货品出库</p>
                                    <p class="hoverBtn" v-if="column.GoodSnowID" style="color:#E6A23C;" @click="goodsMove(item,column)">货品调拨</p>
                                    <p class="hoverBtn" v-if="!column.GoodSnowID" style="color:#F56C6C;" @click="delOneColunm(column.ShelvingColumnSnowID)">删除货口</p>
                                </div>
                            </div>
                            <div class="rowColumnItemStr" >{{column.AllCode}}</div>
                        </div>
                    </div>
                </div>
            </div>
            <div v-else style="margin:100px 0;text-align:center;color:#666666;font-size:16px;">
                该货架还未添加行和货口，请在上方添加
            </div>
        </el-dialog>
        <el-dialog title="货品入库" :visible.sync="goodsInModal" width="1000px" :close-on-click-modal="false" top="100px">
            <el-form :model="goodsInInfo" status-icon :rules="goodsInrules"  ref="inForm" label-width="100px" class="demo-uForm">
                <el-form-item label="存放位置" v-if="goodsInModal">
                    <p>{{nowShelving.WareHouseName}}/{{nowShelving.AreaName}}/{{nowShelving.Code}}号货架/第{{nowShelvingRow.Index}}排/{{nowShelvingColumn.Index}}号口</p>
                </el-form-item>
                <el-form-item label="入库货品" prop="goodSnowID" v-if="goodsInModal">
                    <el-select v-model="goodsInInfo.goodSnowID" placeholder="请选择入库货品"  filterable clearable :disabled="nowShelvingColumn.GoodSnowID == goodsInInfo.goodSnowID" style="width:100%;">
                        <el-option v-for="item in goodsList" :key="item.SnowID" :label="item.Name" :value="item.SnowID" ></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item label="入库数量" prop="goodCount">
                    <el-input v-model="goodsInInfo.goodCount" autocomplete="off"  min="1"></el-input>
                </el-form-item>
                <el-form-item label="入库单价" prop="oneMoney">
                    <el-input v-model="goodsInInfo.oneMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="总额" prop="money">
                    <el-input v-model="goodsInInfo.money" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="货物来源" prop="source">
                    <el-input v-model="goodsInInfo.source" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注">
                    <el-input v-model="goodsInInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitInPostForm()">提交</el-button>
                    <el-button @click="goodsInModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="货品出库" :visible.sync="goodsOutModal" width="1000px" :close-on-click-modal="false" top="100px">
            <el-form :model="goodsOutInfo" status-icon :rules="goodsOutrules"  ref="outForm" label-width="120px" class="demo-uForm">
                <el-form-item label="当前出库货品" prop="goodSnowID" v-if="goodsOutModal">
                    <p>{{nowShelvingColumn.GoodName}}</p>
                </el-form-item>
                <el-form-item label="当前存放位置" v-if="goodsOutModal">
                    <p>{{nowShelving.WareHouseName}}/{{nowShelving.AreaName}}/{{nowShelving.Code}}号货架/第{{nowShelvingRow.Index}}排/{{nowShelvingColumn.Index}}号口</p>
                </el-form-item>
                <el-form-item label="当前库存数量" v-if="goodsOutModal">
                    <p>{{nowShelvingColumn.GoodCount}}</p>
                </el-form-item>
                <el-form-item label="出库数量" prop="ogoodCount" v-if="goodsOutModal">
                    <el-input v-model="goodsOutInfo.ogoodCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库金额" prop="OutMoney">
                    <el-input v-model="goodsOutInfo.OutMoney" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="出库用途" prop="target">
                    <el-input v-model="goodsOutInfo.target" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="备注" prop="bz">
                    <el-input v-model="goodsOutInfo.bz" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitOutPostForm()">提交</el-button>
                    <el-button @click="goodsOutModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="货品调拨" :visible.sync="goodsMoveModal" width="1000px" :close-on-click-modal="false" top="100px">
            <el-form :model="goodsMoveInfo" status-icon :rules="goodsMoverules"  ref="moveForm" label-width="120px" class="demo-uForm">
                <el-form-item label="调拨前存放位置" v-if="goodsMoveModal">
                    <p>{{nowShelving.WareHouseName}}/{{nowShelving.AreaName}}/{{nowShelving.Code}}号货架/第{{nowShelvingRow.Index}}排/{{nowShelvingColumn.Index}}号口</p>
                </el-form-item>
                <el-form-item label="调拨后存放位置" prop="mposition" v-if="goodsMoveModal">
                    <el-cascader v-model="goodsMoveInfo.mposition"  :props="storeprops" placeholder="选择存放位置"  filterable style="width:100%;"></el-cascader>
                </el-form-item>
                <el-form-item label="调入数量" prop="mgoodCount" v-if="goodsMoveModal">
                    <el-input v-model="goodsMoveInfo.mgoodCount" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item label="调拨原因" prop="dbyy">
                    <el-input v-model="goodsMoveInfo.dbyy" autocomplete="off"></el-input>
                </el-form-item>
                <el-form-item>  
                    <el-button type="primary" @click="submitMovePostForm()">提交</el-button>
                    <el-button @click="goodsMoveModal = false">取消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
export default {
  name: "UnitManage",

  data() {
    // var name = (rule, value, callback) => {
    //   if (value === "" || value == null) {
    //     callback(new Error("请输入仓库名称"));
    //   } else {
    //     callback();
    //   }
    // };
    var code = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入货架代码"));
      } else {
        callback();
      }
    };
    var areaSnowID = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择仓库区域"));
      } else {
        callback();
      }
    };
    var index= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入排序'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('排序只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };

    var rowCount= (rule, value, callback) => {
      if (this.autoAdd=='1' && value === '') {
          callback(new Error('请输入货架总行数'));
        } else if(this.autoAdd=='1' && typeof value != 'number' && isNaN(value)){
             callback(new Error('货架总行数只能是数字'));
        }else if(this.autoAdd=='1' && !/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };

    var columnCount= (rule, value, callback) => {
        if(this.autoAdd=='1' && value === '') {
          callback(new Error('请输入每行货口数'));
        } else if(this.autoAdd=='1' && typeof value != 'number' && isNaN(value)){
             callback(new Error('每行货口数只能是数字'));
        }else if(this.autoAdd=='1' && !/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };


    var count= (rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else {
          callback();
        }
    };

    var money=(rule, value, callback) => {
      if (value === '') {
          callback(new Error('请输入金额'));
        } else if(typeof value != 'number' && isNaN(value)){
             callback(new Error('金额只能是数字'));
        }else if(value<0){
            callback(new Error('金额必须大于0'));
        }else {
          callback();
        }
    };

    var source = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入货物来源"));
      } else {
        callback();
      }
    }; 

    var goodSnowID=(rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请选择货品"));
      } else {
        callback();
      }
    }; 
    
    var target = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入出库用途"));
      } else {
        callback();
      }
    }; 

    var ogoodCount= (rule, value, callback) => {
      if (value === '') {
            callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else if(value > this.nowShelvingColumn.GoodCount){
            callback(new Error('出库数量不能大于库存数量'));
        }else {
          callback();
        }
    };

    var mposition=(rule, value, callback) => {
      if (value.length==0) {
        callback(new Error("请选择调拨后存放位置"));
      }else if(value[4] === this.nowShelvingColumn.ShelvingColumnSnowID){
        callback(new Error("请选择调拨前后位置不能一样"));
      } else {
        callback();
      }
    };  

    var mgoodCount= (rule, value, callback) => {
      if (value === '') {
            callback(new Error('请输入数量'));
        } else if(typeof value != 'number' && isNaN(value)){
            callback(new Error('数量只能是数字'));
        }else if(!/^\+?([1-9][0-9]*)$/.test(value)){
            callback(new Error('只能输入大于0的整数'));
        }else if(value > this.nowShelvingColumn.GoodCount){
            callback(new Error('调拨数量不能大于库存数量'));
        }else {
          callback();
        }
    };

    var dbyy = (rule, value, callback) => {
      if (value === "" || value == null) {
        callback(new Error("请输入调拨原因"));
      } else {
        callback();
      }
    }; 
    return {
        storeprops: {
            lazy: true,
            //checkStrictly:true,
            lazyLoad :(node, resolve)=> {
                var src="WareHouse/GetDataList"
                var type="1"
                var params={}
                var leaf=false
                
                if(node && node.data!=undefined){
                    if(node.data.type=="1"){
                        src="Area/GetDataList"
                        type="2"
                        params={
                            wareHouseSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="2"){
                        src="Shelving/GetDataList"
                        type="3"
                        params={
                            areaSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="3"){
                        src="ShelvingRow/GetDataList"
                        type="4"
                        params={
                            shelvingSnowID:node.data.snowid
                        }
                    }else if(node.data.type=="4"){
                        src="ShelvingColumn/GetDataList"
                        type="5"
                        leaf=true
                        params={
                            shelvingRowSnowID:node.data.snowid
                        }
                    }
                }
                var nodes=[]
                
                this.$http.post(src,params)
                .then((response) => {
                    if(response.data.SUCCESS){   
                        var l=response.data.DATA
                        l.sort(function (n, m) {
                            return n.Index - m.Index;
                        });
                        for(var i=0;i<l.length;i++){
                            let disabled=false
                            if(type=="1"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name+"("+l[i].Type+")",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="2"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="3"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:l[i].Code+"号货架",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="4"){
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:"第"+l[i].Index+"行",
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }else if(type=="5"){
                                let name=l[i].Index+"号口"
                                if(l[i].GoodSnowID){
                                    name+="(现存："+l[i].GoodName+"×"+l[i].GoodCount+")"
                                }
                                if(this.nowShelvingColumn.ShelvingColumnSnowID == l[i].SnowID){
                                    disabled=true
                                }else{
                                    if(l[i].GoodSnowID && l[i].GoodSnowID != this.nowShelvingColumn.GoodSnowID){
                                        disabled=true
                                    }
                                }
                                
                                nodes.push({
                                    type:type,
                                    value:l[i].SnowID,
                                    label:name,
                                    snowid:l[i].SnowID,
                                    leaf: leaf,
                                    disabled:disabled,
                                })
                            }  
                        }
                        resolve(nodes);
                    }else{
                        resolve(nodes);
                    }
                })
            }
        },
        canDo:true,
        showPagination:false,
        name:"",
        code:"",
        ck:"",
        ckList:[],
        area:"",
        areaList:[],

        fck:"",
        fareaList:[],
        postList:[],
        pageIndex:1,
        pageSize:10,
        Total:0,

        autoAdd:"0",


        doTypeStr:"",
        doType:"",
        postModal:false,
        postInfo:{},
        postrules: {
            // name: [{ validator: name, trigger: "blur" }],
            code: [{ validator: code, trigger: "blur" }],
            areaSnowID: [{ validator: areaSnowID, trigger: "blur" }],
            index: [{ validator: index, trigger: "blur" }],
            rowCount: [{ validator: rowCount, trigger: "blur" }],
            columnCount: [{ validator: columnCount, trigger: "blur" }],
        },

        rackModal:false,
        rowColumnList:[],


        goodsList:[],

        nowShelving:null,
        nowShelvingRow:null,
        nowShelvingColumn:null,
        addColumnCount:"",


        goodsInModal:false,
        goodsInInfo:{},
        goodsInrules:{
            goodSnowID: [{ validator: goodSnowID, trigger: "blur" }],
            goodCount: [{ validator: count, trigger: "blur" }],
            oneMoney: [{ validator: money, trigger: "blur" }],
            money: [{ validator: money, trigger: "blur" }],
            source: [{ validator: source, trigger: "blur" }],
        },


        goodsOutModal:false,
        goodsOutInfo:{},
        goodsOutrules:{
            ogoodCount: [{ validator: ogoodCount, trigger: "blur" }],
            OutMoney: [{ validator: money, trigger: "blur" }],
            target: [{ validator: target, trigger: "blur" }],
        },

        goodsMoveModal:false,
        goodsMoveInfo:{},
        goodsMoverules:{
            mposition: [{ validator: mposition, trigger: "blur" }],
            mgoodCount: [{ validator: mgoodCount, trigger: "blur" }],
            dbyy: [{ validator: dbyy, trigger: "blur" }],
        },
    };
  },
  mounted() {
    var getQueryData=this.getQueryData(this.$route.name)
    if(getQueryData!= undefined){
        this.pageIndex= getQueryData.pageIndex
        this.name=getQueryData.name
        this.code=getQueryData.code
        this.area=getQueryData.areaSnowID
    }
    this.loadItem=null
    this.getCkList()
    this.getGoodsList()
    this.getPostList()
  },
  methods: {
    getGoodsList(){
        this.$http.post("Good/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.goodsList=response.data.DATA
                } 
            })
    },
    getCkList(){
        this.$http.post("WareHouse/GetDataList", {})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.ckList=response.data.DATA
                } 
            })
    },

    searchCK(e){
        this.area=""
        this.areaList=[]
        if(e!=""){
            this.getSearchCKArea(e)
        }
    },

    getSearchCKArea(id){
        this.$http.post("Area/GetDataList", {WareHouseSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.areaList=response.data.DATA
                } 
            })
    },

    fromCK(e){
        this.postInfo.areaSnowID=""
        this.fareaList=[]
        if(e!=""){
            this.getFromCKArea(e)
        }
    },
    getFromCKArea(id){
        this.$http.post("Area/GetDataList", {WareHouseSnowID:id})
            .then((response) => {
                if (response.data.SUCCESS) {
                    this.fareaList=response.data.DATA
                } 
            })
    },


    getPostList(){
        this.showLoading("请稍后")
        var params={
            pageIndex:this.pageIndex,
            pageSize:this.pageSize,
            // name:this.name,
            code:this.code,
            areaSnowID:this.area,
        }
        this.setQueryData(this.$route.name,params)
        this.$http.post("Shelving/GetDataList", params)
            .then((response) => {
            this.hideLoading()
            if (response.data.SUCCESS) {
                this.postList=response.data.DATA
                this.Total=response.data.TOTALCOUNT
            } else {
                this.postList=[]
                this.Total=0
                this.$message.error(response.data.MSG)
            }
            })
            .catch((error) => {
                this.hideLoading()
                this.postList=[]
                this.Total=0
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    searchPost(){
        this.pageIndex=1
        this.getPostList()
    },
    PGChange(e){
        this.pageIndex=e
        this.getPostList()
    },

    addPost(){
        this.fck=""
        this.fareaList=[]
        this.postInfo={
            //name:"",
            code:"",
            areaSnowID:"",
            index:"",
            rowCount:0,
            columnCount:0,
        }
        this.doTypeStr="添加货架"
        this.doType="1"
        this.postModal=true
    },
    sendAdd(){
        this.canDo=false
        this.showLoading("请稍后")
        var url="Shelving/InsertData"
        if(this.autoAdd=='1'){
            url="Shelving/InsertShelving"
        }
        this.$http.post(url, this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已添加")
                    this.getPostList()
                    this.postModal=false
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
             });
    },
    editPost(item){
        this.fck=item.WareHouseSnowID
        this.getFromCKArea(item.WareHouseSnowID)
        this.postInfo={
            snowID:item.SnowID,
            //name:item.Name,
            code:item.Code,
            areaSnowID:item.AreaSnowID,
            index:item.Index,
        }
        this.doTypeStr="修改货架"
        this.doType="2"
        this.postModal=true
    },
    sendEdit(){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Shelving/UpdateData", this.postInfo)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已修改")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    submitPostForm(){
        if (this.canDo) {
            this.$refs.uForm.validate((valid) => {
                if (valid) {
                    if(this.doType=="1"){
                        this.sendAdd()
                    }else if(this.doType=="2"){
                        this.sendEdit()
                    }
                } else {
                    return false;
                }
            });
        }
    },
    
    delPost(item){
        if(this.canDo){
            this.$confirm('确定删除该货架？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDel(item.SnowID)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDel(id){
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("Shelving/DeleteData", {snowid:id})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("已删除")
                    this.postModal=false
                    this.getPostList()
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                } 
            });
    },
    itemPost(item){
        this.nowShelving=item
        this.nowShelvingRow=null
        this.nowShelvingColumn=null
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("ShelvingColumn/GetWareHouseTree", {shelvingSnowID:item.SnowID})
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.initItemPost(response.data.DATA)
                } else {
                    this.initItemPost([])
                }
            })
            .catch((error) => {
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                } 
                this.initItemPost([])
            });
    },
    initItemPost(list){
        if(list.length>0){
            let a=list[0]
            if(a.AreaList.length>0){
                let b=a.AreaList[0]
                if(b.ShelvingList.length>0){
                    let c=b.ShelvingList[0]
                    if(c.ShelvingRowList.length>0){
                        this.rowColumnList=c.ShelvingRowList
                    }
                }
            }
        }
        console.log(this.rowColumnList)
        this.rackModal=true
    },
    changeRowIndex(id){
        for(var i=0;i<this.rowColumnList.length;i++){
            if(this.rowColumnList[i].ShelvingRowSnowID ==id){
                this.sendChangeRowIndex(this.rowColumnList[i])
            }
        }
    },
    sendChangeRowIndex(item){
        this.canDo=false
        this.showLoading("请稍后")
       
        var params={
            snowID:item.ShelvingRowSnowID,
            index:item.Index,
        }
        this.$http.post("ShelvingRow/UpdateData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.itemPost(this.nowShelving)
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                } 
            });
    },
    addColumnByRow(){
        if(this.canDo){
            if(this.addColumnCount === '') {
                this.$message.error('请输入货口数')
            } else if(typeof this.addColumnCount != 'number' && isNaN(this.addColumnCount)){
                this.$message.error('货口数只能是数字')
            }else if(!/^\+?([1-9][0-9]*)$/.test(this.addColumnCount)){
                this.$message.error('只能输入大于0的整数')
            }else {
                this.sendAddColunm()
            }
        }
    },
    sendAddColunm(){
        this.canDo=false
        this.showLoading("请稍后")
        var index=1
        if(this.rowColumnList.length>0){
            index = this.rowColumnList[this.rowColumnList.length-1].Index + 1
        }
        var params={
            shelvingsnowid:this.nowShelving.SnowID,
            columnCount:this.addColumnCount,
            index:index,
        }
        this.$http.post("ShelvingRow/InsertData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.itemPost(this.nowShelving)
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                } 
            });
    },
    addOneColunm(rowid){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var index=1
            var hList=[]
            for(var i=0;i<this.rowColumnList.length;i++){
                if(this.rowColumnList[i].ShelvingRowSnowID == rowid){
                    hList=this.rowColumnList[i].ShelvingColumnList
                    break;
                }
            }
            if(hList.length>0){
                var btw=false
                for(var j=0;j<hList.length;j++){
                    if(j+1 < hList.length && (hList[j+1].Index - hList[j].Index) >1){
                        btw=true
                        index=hList[j].Index+1
                        break;
                    }
                }
                if(!btw){
                    index=hList[hList.length-1].Index+1
                }
            }
            var params={
                shelvingRowSnowID:rowid,
                index:index,
            }
            this.$http.post("ShelvingColumn/InsertData", params)
                .then((response) => {
                    this.canDo=true
                    this.hideLoading()
                    if (response.data.SUCCESS) {
                        this.itemPost(this.nowShelving)
                    } else {
                        this.$message.error(response.data.MSG)
                    }
                })
                .catch((error) => {
                    this.$message.error("请求出错")
                    this.hideLoading()
                    this.canDo=true  
                    if (error.isAxiosError) {
                        if(error.response.status==401){
                            this.logout()
                        }
                    } 
                });
        }
    },
    delOneColunm(id){
        if(this.canDo){
            this.$confirm('确定删除此货口？', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                this.sendDelOneColunm(id)
            }).catch(() => {
                this.$message.info("已取消")    
            });
        }
    },
    sendDelOneColunm(id){
        if(this.canDo){
            this.canDo=false
            this.showLoading("请稍后")
            var params={
                snowid:id,
        }
        this.$http.post("ShelvingColumn/DeleteData", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.itemPost(this.nowShelving)
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true  
                if (error.isAxiosError) {
                    if(error.response.status==401){
                        this.logout()
                    }
                } 
            });
        }
    },
    goodsIn(row,column){
            this.nowShelvingRow=row
            this.nowShelvingColumn=column
            var gid=""
            if(column.GoodSnowID){
                gid=column.GoodSnowID
            }
            this.goodsInInfo={
                goodSnowID:gid,
                shelvingColumnSnowID:column.ShelvingColumnSnowID,
                goodCount:"",
                oneMoney:"",
                money:"",
                source:"",
                bz:"",
            }
            this.goodsInModal=true
    },
    submitInPostForm(){
        if (this.canDo) {
            this.$refs.inForm.validate((valid) => {
                if (valid) {
                    this.sendGoodsIn()
                } else {
                    return false;
                }
            });
        }
    },
    sendGoodsIn(){
        var params={
            goodSnowID:this.goodsInInfo.goodSnowID,
            shelvingColumnSnowID:this.goodsInInfo.shelvingColumnSnowID,
            goodCount:parseInt(this.goodsInInfo.goodCount),
            oneMoney:parseFloat(this.goodsInInfo.oneMoney),
            money:parseFloat(this.goodsInInfo.money),
            source:this.goodsInInfo.source,
            bz:this.goodsInInfo.bz,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/InWareHouse", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    this.$message.success("入库完成")
                    this.goodsInModal=false
                    this.itemPost(this.nowShelving)
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    goodsOut(row,column){
        this.nowShelvingRow=row
        this.nowShelvingColumn=column
        this.goodsOutInfo={
            ogoodCount:"",
            OutMoney:"",
            target:"",
            bz:"",
        }
        this.goodsOutModal=true
    },
    submitOutPostForm(){
        if (this.canDo) {
            this.$refs.outForm.validate((valid) => {
                if (valid) {
                    this.sendOutGoods()
                } else {
                    return false;
                }
            });
        }
    },
    sendOutGoods(){
        var params={
            shelvingColumnSnowID:this.nowShelvingColumn.ShelvingColumnSnowID,
            goodCount:parseInt(this.goodsOutInfo.ogoodCount),
            OutMoney:parseFloat(this.goodsOutInfo.OutMoney),
            bz:this.goodsOutInfo.bz,
            target:this.goodsOutInfo.target,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/OutWareHouse", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    //this.$message.success("出库完成")
                    this.afterOutFlagCount("出库完成")
                    this.goodsOutModal=false
                    this.itemPost(this.nowShelving)
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    goodsMove(row,column){
        this.nowShelvingRow=row
        this.nowShelvingColumn=column
        this.goodsMoveInfo={
            mposition:[],
            mgoodCount:"",
            dbyy:"",
        }
        this.goodsMoveModal=true
    },
    submitMovePostForm(){
        if (this.canDo) {
            this.$refs.moveForm.validate((valid) => {
                if (valid) {
                    this.sendGoodsMove()
                } else {
                    return false;
                }
            });
        }
    },
    sendGoodsMove(){
        var params={
            oldShelvingColumnSnowID:this.nowShelvingColumn.ShelvingColumnSnowID,
            shelvingColumnSnowID:this.goodsMoveInfo.mposition[4],
            goodCount:parseInt(this.goodsMoveInfo.mgoodCount),
            bz:this.goodsMoveInfo.dbyy,
        }
        this.canDo=false
        this.showLoading("请稍后")
        this.$http.post("GoodIn/WareHouseDispatch", params)
            .then((response) => {
                this.canDo=true
                this.hideLoading()
                if (response.data.SUCCESS) {
                    //this.$message.success("调拨完成")
                    this.afterOutFlagCount("调拨完成")
                    this.goodsMoveModal=false
                    this.itemPost(this.nowShelving)
                } else {
                    this.$message.error(response.data.MSG)
                }
            })
            .catch((error) => {
                this.$message.error("请求出错")
                this.hideLoading()
                this.canDo=true
                if (error.isAxiosError) {
                   if(error.response.status==401){
                    this.logout()
                   }
                }
            });
    },
    afterOutFlagCount(str){
        var params={
            wareHouseSnowID:this.nowShelving.WareHouseSnowID,
            goodSnowID:this.nowShelvingColumn.GoodSnowID,
        }
        this.$http.post("WarningSetting/GetDataList", params)
            .then((response) => {
                if (response.data.SUCCESS && response.data.DATA.length>0) {
                    var d=response.data.DATA[0]
                    if(d.GoodCount >0 && d.WareHouseCount<d.GoodCount){
                        var tag='商品 <span style="color:#409EFF;">'+this.nowShelvingColumn.GoodName+'</span> 在 <span style="color:#E6A23C;">'+this.nowShelving.WareHouseName+'</span> 中的库存还剩余 <span style="color:#F56C6C;">'+d.WareHouseCount+'</span> 件,低于阈值所设置的  <span style="color:#F56C6C;">'+d.GoodCount+'</span> 件，请及时补充货品！'
                        this.$alert(tag, str,{
                            dangerouslyUseHTMLString: true 
                        });
                       
                    }else{
                        this.$message.success(str)
                    }
                }else{
                    this.$message.success(str)
                }
            })
    },
  },
  computed: {
  },
  watch: {
  },
};
</script>
<style >
.rowCollapse{border-bottom:1px solid #ADADAD;padding:12px 0;background-color:#F8F8F8;}

.rowColumnItem{overflow:hidden;margin-left:20px;margin-top:20px;float:left;border-radius:3px;}
.rowColumnItem .rowColumnItemBox{overflow:hidden;width:165px;height:60px;background-color:#FFFFFF;cursor: pointer;position:relative;display: flex;
justify-content:center;align-items:Center;}
.rowColumnItem .rowColumnItemStr{height:20px;text-align:center;font-size:12px;color:#FFFFFF;line-height:20px;width:165;}
.hasGoods .rowColumnItemStr{background-color:#409EFF}
.noGoods .rowColumnItemStr{background-color:#67C23A}
.rowColumnItemBox  .hoverBox{background-color:rgba(0,0,0,0.8);display:none;position:absolute;left:0;right:0;top:0;bottom:0;}
.rowColumnItem .rowColumnItemBox:hover .hoverBox{display:block;}
.hoverBox .hoverBtn{margin-top:3px;cursor:pointer;text-align:center;font-size:12px;line-height: normal}

.goodsItemBox{width:165px;height:60px;overflow:hidden;}
.goodsItemBox .left{overflow:hidden;float:left; width:50px;height:50px;margin-left:5px;margin-top:5px;background-color:#F8F8F8;}
.goodsItemBox .left img{display:block;max-width:100%;max-height:100%;margin:0 auto;}
.goodsItemBox .right{height:50px;width:100px;margin-left:5px;margin-top:5px;float:left;}
.goodsItemBox .right p{font-size:12px;overflow:hidden;white-space: nowrap;text-overflow: ellipsis;}
.goodsItemBox .right p:first-of-type{font-weight: bold;}
.nogtop{margin-top:12px!important;}
</style>